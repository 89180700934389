<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>任务管理</template>
      <template v-slot:secondMenu>邀请审核</template>
    </breadcrumb-nav>
    <el-card>
    <el-form :model="listQuery" ref="listQuery" :inline="true" class="demo-form-inline">
      <el-form-item label="状态" prop="status">
        <el-select v-model="listQuery.status" style="width:100%" @clear="cancelStatus" clearable placeholder="状态">
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="上传日期" prop="date">
        <el-date-picker
          v-model="listQuery.date"
          type="daterange"
          align="right"
          prop="date"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search">查询</el-button>
        <el-button @click="resetForm('listQuery')">重置</el-button>
      </el-form-item>
    </el-form>
    <el-table style="width: 100%" border :data="list">
      <el-table-column type="index" label="序号" width="80px" align="center">
      </el-table-column>
      <el-table-column prop="imageUrl" label="头像" width="100px">
        <template slot-scope="{ row }">
          <img :src="row.imageUrl" alt="" style="width: 50px; height: 50px" />
        </template>
      </el-table-column>
      <el-table-column prop="showName" label="用户" width="120px">
      </el-table-column>
      <el-table-column prop="createTime" label="上传时间">
        <template slot-scope="scope">{{ scope.row.createTime | dateFormat }}</template>
      </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">{{ scope.row.status | formatStatus }}</template>
      </el-table-column>
      <el-table-column label="操作" width="100px">
        <template slot-scope="scope">
          <el-row>
            <el-button type="primary" v-if="scope.row.status!==2" size="mini" icon="el-icon-view" @click="auditDetail(scope.row.auditId, scope.row.userId)">详情
            </el-button>
            <el-button type="warning" v-if="scope.row.status===2" size="mini" icon="el-icon-postcard" @click="auditDetail(scope.row.auditId, scope.row.userId)">审核
            </el-button>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="listQuery.page"
      :page-sizes="[5, 10]"
      :page-size="listQuery.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from '../../common/BreadcrumbNav'
import { getRewardAuditListRequest } from '../../../network/task'

export default {
  name: 'InviteRecordList',
  components: {
    BreadcrumbNav
  },
  filters: {
    formatStatus (value) {
      switch (value) {
        case 1:
          return '待上传'
        case 2:
          return '待审核'
        case 3:
          return '已发放'
        case 4:
          return '审核拒绝'
        case 5:
          return '过期'
        default:
          return '过期'
      }
    }
  },
  data () {
    return {
      listQuery: {
        status: 0,
        rewardType: 0,
        date: '',
        isAdmin: 1,
        page: 1,
        size: 10,
        rewardSource: 14
      },
      // 总共数据条数
      total: 0,
      // 列表展示的数据
      list: [],
      statusOptions: [
        {
          label: '全部',
          value: 0
        },
        {
          label: '待上传',
          value: 1
        },
        {
          label: '待审核',
          value: 2
        },
        {
          label: '已发放',
          value: 3
        },
        {
          label: '审核拒绝',
          value: 4
        },
        {
          label: '过期',
          value: 5
        }
      ]
    }
  },
  // 组件挂载完毕发请求
  mounted () {
    // 获取列表数据方法
    this.getList()
  },
  methods: {
    getList () {
      const query = {
        page: this.listQuery.page,
        size: this.listQuery.size,
        status: this.listQuery.status,
        rewardSource: this.listQuery.rewardSource
      }
      if (this.listQuery.date !== null && this.listQuery.date !== undefined && this.listQuery.date.length > 1) {
        query.startTime = this.listQuery.date[0]
        query.endTime = this.listQuery.date[1]
      }
      getRewardAuditListRequest(query).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取列表失败', 'error')
        }
        this.list = result.data.list
        for (const item of this.list) {
          if (item.showAvatar.includes('http')) {
            item.imageUrl = item.showAvatar
          } else {
            item.imageUrl = window.g.MEDIA_DOWNLOAD_URL + item.showAvatar
          }
        }
        this.total = result.data.total
      })
    },
    cancelStatus () {
      this.listQuery.status = 0
    },
    search () {
      this.listQuery.page = 1
      this.getList()
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
      this.listQuery.page = 1
    },
    handleSizeChange (newSize) {
      this.listQuery.size = newSize
      this.getRewardList()
    },
    handleCurrentChange (newPage) {
      this.listQuery.page = newPage
      this.getRewardList()
    },
    auditDetail (auditId, userId) {
      this.$router.push({ path: '/inviteAuditDetail', query: { auditId: auditId, userId: userId } })
    }
  }
}
</script>

<style scoped>

</style>
